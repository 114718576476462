import React, { useState } from "react";

import { useRecoilState } from "recoil";

import { LinearProgress, MenuItem } from "@material-ui/core";

// @material-ui components
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import {
  dialogState,
  handleErrorState,
  loadingOverlayState,
  snackBarState,
} from "../../GlobalAtoms";
import SaveButton from "../../components/Buttons/SaveButton";

import { settings, locale, getHeaders } from "../../settings";
import TextFieldWrapper from "../../components/Form/TextFieldWrapper";

import FormWrapper from "../../components/Form/FormWrapper";
import HandleChange from "../../components/Form/HandleChange";
import DividerWrapper from "../../components/DividerWrapper";
import CheckboxWrapper from "../../components/Form/CheckboxWrapper";
import InputMaskWrapper from "../../components/Form/InputMaskWrapper";


const StoreAdd: React.FC<{
  setData: React.Dispatch<any>;
  data: {}[];
  companies: {}[];
}> = (props: any) => {
  // eslint-disable-next-line no-unused-vars
  const [loadingOverlay, setLoadingOverlayState] = useRecoilState(loadingOverlayState);
  // eslint-disable-next-line no-unused-vars
  const [handleError, setHandleError] = useRecoilState(handleErrorState);
  const [isSubmitting, setSubmitting] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [snackBar, setSnackBar] = useRecoilState(snackBarState);
  const [dialog, setDialogState] = useRecoilState(dialogState);

  const [values, setValue] = useState({
    company_id: null,
    name: null,
    initials: null,
    corporate_name: null,
    tax_id: null,
    contact: null,
    phone: null,
    total_per_week: null,
    address: null,
    number: null,
    address2: null,
    district: null,
    city: null,
    province: null,
    postal_code: null,
    quantity_coin_5: null,
    quantity_coin_10: null,
    quantity_coin_25: null,
    quantity_coin_50: null,
    quantity_coin_100: null,
    quantity_cash_2: null,
    quantity_cash_5: null,
    quantity_cash_10: null,
    quantity_cash_20: null,
    quantity_cash_50: null,
    quantity_cash_100: null,
    quantity_cash_200: null,
    coin_is_enabled: true,
    coin_use_from_company: true,
    coin_delivery_fee: '0,00',
    coin_per_thousand_fee: '0,00',
    cash_is_enabled: true,
    cash_use_from_company: true,
    cash_delivery_fee: '0,00',
  });

  const handleChange = (name: string) => (event: any) => {
    const data = HandleChange(name, event);
    setValue({ ...values, [data.name]: data.value });
  };

  const store = (event: any) => {
    event.preventDefault();

    setSubmitting(true);
    setLoadingOverlayState(true);
    setDialogState({
      ...dialog,
      open: false,
    });

    const url = `${settings().apiUrl}/api/stores`;
    const headers = getHeaders();
    const body = JSON.stringify({
      company_id: values.company_id,
      name: values.name,
      initials: values.initials,
      corporate_name: values.corporate_name,
      tax_id: values.tax_id,
      contact: values.contact,
      phone: values.phone,
      total_per_week: values.total_per_week,
      address: values.address,
      number: values.number,
      address2: values.address2,
      district: values.district,
      city: values.city,
      province: values.province,
      postal_code: values.postal_code,
      quantity_coin_5: values.quantity_coin_5,
      quantity_coin_10: values.quantity_coin_10,
      quantity_coin_25: values.quantity_coin_25,
      quantity_coin_50: values.quantity_coin_50,
      quantity_coin_100: values.quantity_coin_100,
      quantity_cash_2: values.quantity_cash_2,
      quantity_cash_5: values.quantity_cash_5,
      quantity_cash_10: values.quantity_cash_10,
      quantity_cash_20: values.quantity_cash_20,
      quantity_cash_50: values.quantity_cash_50,
      quantity_cash_100: values.quantity_cash_100,
      quantity_cash_200: values.quantity_cash_200,
      coin_is_enabled: values.coin_is_enabled,
      coin_use_from_company: values.coin_use_from_company,
      coin_delivery_fee: values.coin_delivery_fee,
      coin_per_thousand_fee: values.coin_per_thousand_fee,
      cash_is_enabled: values.cash_is_enabled,
      cash_use_from_company: values.cash_use_from_company,
      cash_delivery_fee: values.cash_delivery_fee,
      is_enabled: true,
      country: 'Brazil',
    });

    axios
      .post(url, body, { headers })
      .then((response: { data: { data: {} } }) => {
        const { data } = props;
        data.push(response.data.data);
        props.setData([...data]);
        setSnackBar({
          open: true,
          type: "success",
          message: 'Loja adicionada com sucesso',
        });
        setSubmitting(false);
        setLoadingOverlayState(false);
      })
      .catch((error) => {
        setLoadingOverlayState(false);
        setSubmitting(false);
        setHandleError({ error: true, response: error.response })
      });
  }

  return (
    <FormWrapper onSubmit={(event) => store(event)}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} lg={4}>
          <TextFieldWrapper
            label={"Cliente"}
            name={"company_id"}
            id={"company_id"}
            onChange={handleChange("company_id")}
            value={values.company_id}
            required
            select
          >
            {props.companies.map((option: any) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name} - {option.initials}
              </MenuItem>
            ))}
          </TextFieldWrapper>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextFieldWrapper
            label={"Nome da Loja"}
            name={"name"}
            id={"name"}
            onChange={handleChange("name")}
            value={values.name}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextFieldWrapper
            label={"Sigla"}
            name={"initials"}
            id={"initials"}
            onChange={handleChange("initials")}
            value={values.initials}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextFieldWrapper
            label={"Razão Social"}
            name={"corporate_name"}
            id={"corporate_name"}
            onChange={handleChange("corporate_name")}
            value={values.corporate_name}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <InputMaskWrapper
            mask={'99.999.999/9999-99'}
            name={"tax_id"}
            id={"tax_id"}
            onChange={handleChange("tax_id")}
            value={values.tax_id}
          >
            <TextFieldWrapper label={"CNPJ"} required />
          </InputMaskWrapper>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextFieldWrapper
            label={"Responsável"}
            name={"contact"}
            id={"contact"}
            onChange={handleChange("contact")}
            value={values.contact}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <InputMaskWrapper
            mask={'(99) 99999-9999'}
            name={"phone"}
            id={"phone"}
            onChange={handleChange("phone")}
            value={values.phone}
          >
            <TextFieldWrapper label={"Telefone"} required />
          </InputMaskWrapper>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextFieldWrapper
            label={"Valor Total por Pedido"}
            name={"total_per_week"}
            id={"total_per_week"}
            onChange={handleChange("total_per_week")}
            value={values.total_per_week}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <DividerWrapper />
      </Grid>
      <h4>Endereço:</h4>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} lg={4}>
          <TextFieldWrapper
            label={"Endereço"}
            name={"address"}
            id={"address"}
            onChange={handleChange("address")}
            value={values.address}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextFieldWrapper
            label={"Número"}
            name={"number"}
            id={"number"}
            onChange={handleChange("number")}
            value={values.number}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextFieldWrapper
            label={"Complemento"}
            name={"address2"}
            id={"address2"}
            onChange={handleChange("address2")}
            value={values.address2}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextFieldWrapper
            label={"Bairro"}
            name={"district"}
            id={"district"}
            onChange={handleChange("district")}
            value={values.district}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextFieldWrapper
            label={"Cidade"}
            name={"city"}
            id={"city"}
            onChange={handleChange("city")}
            value={values.city}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <InputMaskWrapper
            mask={'aa'}
            name={"province"}
            id={"province"}
            onChange={handleChange("province")}
            value={values.province}
          >
            <TextFieldWrapper label={"UF"} required />
          </InputMaskWrapper>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <InputMaskWrapper
            mask={'99.999-999'}
            name={"postal_code"}
            id={"postal_code"}
            onChange={handleChange("postal_code")}
            value={values.postal_code}
          >
            <TextFieldWrapper label={"CEP"} required />
          </InputMaskWrapper>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <DividerWrapper />
      </Grid>
      <h4>Serviços Disponíveis</h4>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} lg={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <CheckboxWrapper
                label={"Aquisição de Troco"}
                name={"coin_is_enabled"}
                id={"coin_is_enabled"}
                onChange={handleChange("coin_is_enabled")}
                value={values.coin_is_enabled}
                checked={values.coin_is_enabled}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CheckboxWrapper
                label={"Usar do Cliente (somente valor)"}
                name={"coin_use_from_company"}
                id={"coin_use_from_company"}
                onChange={handleChange("coin_use_from_company")}
                value={values.coin_use_from_company}
                checked={values.coin_use_from_company}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextFieldWrapper
                label={"Valor do Frete (R$)"}
                name={"coin_delivery_fee"}
                id={"coin_delivery_fee"}
                onChange={handleChange("coin_delivery_fee")}
                value={values.coin_delivery_fee}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextFieldWrapper
                label={"Custo do Milheiro (R$)"}
                name={"coin_per_thousand_fee"}
                id={"coin_per_thousand_fee"}
                onChange={handleChange("coin_per_thousand_fee")}
                value={values.coin_per_thousand_fee}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <CheckboxWrapper
                label={"Recolhimento de Cédulas"}
                name={"cash_is_enabled"}
                id={"cash_is_enabled"}
                onChange={handleChange("cash_is_enabled")}
                value={values.cash_is_enabled}
                checked={values.cash_is_enabled}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CheckboxWrapper
                label={"Usar do Cliente (somente valor)"}
                name={"cash_use_from_company"}
                id={"cash_use_from_company"}
                onChange={handleChange("cash_use_from_company")}
                value={values.cash_use_from_company}
                checked={values.cash_use_from_company}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextFieldWrapper
                label={"Valor do Frete (R$)"}
                name={"cash_delivery_fee"}
                id={"cash_delivery_fee"}
                onChange={handleChange("cash_delivery_fee")}
                value={values.cash_delivery_fee}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DividerWrapper />
        </Grid>
      </Grid>
      <h4>Limites de Pedido</h4>
      <Grid container spacing={3}>
        <Grid item xs={6} md={2}>
          <TextFieldWrapper
            label={"R$ 0,05"}
            type="text"
            required
            name={"quantity_coin_5"}
            id={"quantity_coin_5"}
            onChange={handleChange("quantity_coin_5")}
            value={values.quantity_coin_5}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <TextFieldWrapper
            label={"R$ 0,10"}
            type="text"
            required
            name={"quantity_coin_10"}
            id={"quantity_coin_10"}
            onChange={handleChange("quantity_coin_10")}
            value={values.quantity_coin_10}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <TextFieldWrapper
            label={"R$ 0,25"}
            type="text"
            required
            name={"quantity_coin_25"}
            id={"quantity_coin_25"}
            onChange={handleChange("quantity_coin_25")}
            value={values.quantity_coin_25}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <TextFieldWrapper
            label={"R$ 0,50"}
            type="text"
            required
            name={"quantity_coin_50"}
            id={"quantity_coin_50"}
            onChange={handleChange("quantity_coin_50")}
            value={values.quantity_coin_50}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <TextFieldWrapper
            label={"R$ 1,00"}
            type="text"
            required
            name={"quantity_coin_100"}
            id={"quantity_coin_100"}
            onChange={handleChange("quantity_coin_100")}
            value={values.quantity_coin_100}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <TextFieldWrapper
            label={"R$ 2,00"}
            type="text"
            required
            name={"quantity_cash_2"}
            id={"quantity_cash_2"}
            onChange={handleChange("quantity_cash_2")}
            value={values.quantity_cash_2}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <TextFieldWrapper
            label={"R$ 5,00"}
            type="text"
            required
            name={"quantity_cash_5"}
            id={"quantity_cash_5"}
            onChange={handleChange("quantity_cash_5")}
            value={values.quantity_cash_5}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <TextFieldWrapper
            label={"R$ 10,00"}
            type="text"
            required
            name={"quantity_cash_10"}
            id={"quantity_cash_10"}
            onChange={handleChange("quantity_cash_10")}
            value={values.quantity_cash_10}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <TextFieldWrapper
            label={"R$ 20,00"}
            type="text"
            required
            name={"quantity_cash_20"}
            id={"quantity_cash_20"}
            onChange={handleChange("quantity_cash_20")}
            value={values.quantity_cash_20}
          />
        </Grid>
        <Grid item xs={12}>
          <DividerWrapper />
        </Grid>
        {isSubmitting && <LinearProgress />}
        <Grid item xs={12}>
          <Box>
            <SaveButton
              type="submit"
              disabled={isSubmitting}
            />
          </Box>
        </Grid>
      </Grid>
    </FormWrapper>
  );
};
export default StoreAdd;
